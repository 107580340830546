<template>
  <div>
    <div class="input_box">
      <span>金额：</span>
      <input
        style="width: 200px; height: 25px"
        type="text"
        v-model="amount"
        placeholder="请输入金额"
      />
      <button style="margin-left: 10px" @click="enterInfo()">确定</button>
    </div>
  </div>
</template>
<script>
import { getOpen } from "@/api/index";
export default {
  name: "wxView",
  data() {
    return {
      amount: "",
    };
  },
  methods: {
    enterInfo() {
      getOpen({
        amount: this.amount,
      }).then((res) => {
        if (res?.code == 0) {
          window.location.href = res.data.data.cashierUrl;
          console.log(res);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style>
.input_box {
  width: 100%;
  margin-top: 40px;
  margin-left: 40px;
}
</style>
