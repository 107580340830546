import axios from "axios";

const request = axios.create({
  /*  // https://uapi.jingtonghuishou.com */
  baseURL: "https://uapi.jingtonghuishou.com",
  // baseURL: "http://sand.voicelove.cn",
  timeout: 5000,
});

request.interceptors.request.use((config) => {
  return config;
});

request.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    console.log(err);
  }
);

export default request;
