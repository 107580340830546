<template>
  <div style="width: 100%; height: 100%">
    <div brick-index="7b4484af9ccd48a9" std-leads-brick-name="XrMdl" class="stream-frame_52417"
      style="top: 0rem; left: 0rem; width: 100%; height: 100%">
      <div class="frame-event_0961e">
        <div brick-index="7b4484af9ccd48a9" class="brick-mdl_62840" style="top: 0; left: 0">
          <div class="mdl-content_239fd" style="background-color: rgba(255, 255, 255, 0)">
            <div brick-index="a0a9e078eaad43d5" std-leads-brick-name="XrPicture" class="absolute-frame_7b3f7" style="
                transform: rotate(0deg) scale(1, 1);
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
              ">
              <div class="frame-event_0961e">
                <img brick-index="a0a9e078eaad43d5" class="brick-picture_85091"
                  style="border-radius: 0rem; width: 100%; height: 100%" src="./image/back.jpg" />
              </div>
            </div>
            <div brick-index="e20d4d05867047cf" std-leads-brick-name="XrButton" class="absolute-frame_7b3f7" style="
                left: 0.5867rem;
                top: 6.1867rem;
                width: 8.84rem;
                height: 1.2534rem;
                rotation: 0;
              ">
              <div std-leads-brick-event="LinkEvent" class="frame-event_0961e frame-event-bind" @click="enterInfo">
                <div brick-index="e20d4d05867047cf" class="brick-button_56451" style="
                    color: rgba(67, 0, 1, 1);
                    border-color: rgba(254, 254, 255, 1);
                    border-width: 0.2rem;
                    border-radius: 0.1067rem;
                    background-color: rgba(254, 254, 255, 1);
                    top: 240px;
                    width: 90%;
                    left: 20px;
                    height: 70px;
                    position: absolute;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  ">
                  <div class="button-content_3cced">
                    <span class="text-ellipsis_45b09" style="font-size: 30px">立即回收</span>
                  </div>
                  <div class="ripple-content_e0ef5">
                    <div class="water_32c9e"></div>
                    <div class="hands_9f871"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getConfig } from "@/api/index";
export default {
  name: "wxView",
  data() {
    return {};
  },
  methods: {
    enterInfo() {
      getConfig().then((res) => {
        if (res?.errorCode == 0) {
          window.location.href = res.link;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style></style>
