<template>
  <div class="bg">
    <img class="logo" src="@/assets/h5_logo.png" alt="">
    <div class="por">
      <img class="bodyimg" src="@/assets/h5_activityranking_illustration_img.png" alt="">
      <div class="time">本赛季活动时间：2月21日12:00-3月20日17:00</div>
    </div>
    <div class="titleimg">
      <img style="width: 335px;height: 84px;" src="@/assets/title.png" alt="">
    </div>

    <div class="font">活动奖品：第一名可获得iPhone15 Pro Max，第二名可获得iPad Pro 2023，第三名可获得iPad 2023！</div>

    <div class="but">
      <div @click="change(0)" class="but-item" :class="{active:changIndex==0}">排行榜</div>
      <div @click="change(1)" class="but-item" :class="{active:changIndex==1}">规则说明</div>
    </div>

    <div class="table">
      <div class="tableBg">
        <div v-show="changIndex == 0">
          <div class="tabTitle">本期活动排行榜</div>
          <div class="tabData">
            <div class="dataT" style="border-top-right-radius: 8px;border-top-left-radius: 8px;">
              <div class="top">排名</div>
              <div class="name">昵称</div>
              <div class="top2">积分值</div>
            </div>

            <div class="tabItem" v-for="(item,index) in tableData" :key="index" :class="{itemBg:index%2==0,bor:tableData.length == index+1}">
              <div class="top">{{item.id}}</div>
              <div class="name">{{item.name}}</div>
              <div class="top2">{{item.value}}</div>
            </div>
          </div>
        </div>

        <div v-show="changIndex == 1">
          <div class="font1" style="margin-bottom: 6px;">一、玩法介绍</div>
          <div class="font1">1..购买游戏专属头像框可获得积分，消耗积分可参加游戏。</div>
          <div class="font1">2.本游戏为百分百获得礼物奖励哦，获得的奖励将放在背包中，仅允许赠送给其他用户。</div>
          <div class="font1">3.该玩法仅供娱乐，用户获得的物品不可反向兑换成现金或者价值商品。禁止一切线下交易、收购等不正当行为。平台将对各类以盈利为目的的交易行为进行严厉打击！</div>
          <div class="font1">4.本活动仅限18岁以上用户参加。</div>
          <div class="font1">5.本活动与苹果公司无关。</div>
          <div class="font1">6.根据相关规定，本游戏每日消费限额5000元。</div>
          <div class="font1" style="margin-top: 6px;margin-bottom: 12px ;">二、游戏奖品概率说明</div>

          <div class="dataT" style="border-top-right-radius: 8px;border-top-left-radius: 8px;">
            <div class="item">礼物名称</div>
            <div class="item">获得概率</div>
          </div>

          <div class="tabItem" v-for="(item,index) in gift" :key="index" :class="{itemBg:index%2!==0,bor:gift.length == index+1}">
              <div class="item">{{item.name}}</div>
              <div class="item">{{item.probability}}</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      changIndex : 0,
      tableData: [
        {id: 1,name: '机灵.活跃小宝',value: '74.34w'},
        {id: 2,name: '财来幸运来',value: '63.56w'},
        {id: 3,name: '小宝',value: '26.46w'},
        {id: 4,name: '财来幸运来',value: '18.23w'},
        {id: 5,name: '机灵.活跃小宝',value: '26.46w'},
        {id: 6,name: '财来幸运来',value: '18.23w'},
        {id: 7,name: '小宝',value: '26.46w'},
        {id: 8,name: '财来幸运来',value: '18.23w'},
      ],
      gift: [
        {
          name: '龙年红包',
          probability: '22.2452%'
        },
        {
          name: '龙年对对乐',
          probability: '20.7622%'
        },
        {
          name: '福龙鸣炮',
          probability: '18.5377%'
        },
        {
          name: '花束',
          probability: '17.0547%'
        },
        {
          name: '一炮冲天',
          probability: '11.1226%'
        },
        {
          name: '纯白爱神',
          probability: '特殊奖励'
        },
        {
          name: '南方小土豆',
          probability: '5.932%'
        },
        {
          name: '烟花摩天轮',
          probability: '2.5952%'
        },
        {
          name: '时光荏苒',
          probability: '1.3347%'
        },
        {
          name: '雪中精灵',
          probability: '0.3336%'
        },
        {
          name: '荣耀宫殿',
          probability: '0.037%'
        },
        {
          name: '钢琴情缘',
          probability: '0.0222%'
        },
        {
          name: '灯火阑珊',
          probability: '特殊奖励'
        },
        {
          name: '浪漫夜晚',
          probability: '0.0148%'
        },
        {
          name: '愿得一人心',
          probability: '0.0074%'
        },
        {
          name: '跨洋恋曲',
          probability: '特殊奖励'
        },
      ]
    }
  },

  methods: {
    change(item){
      this.changIndex = item
    },
  }
}
</script>

<style lang="less" scoped>
.bg{
  // width: 100%;
  // height: 100%;
  background-image: url('@/assets/bg.png');
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-color: rgb(255,123,165);
  padding-bottom: 12px;
  box-sizing: border-box;
}

.logo{
  margin-top: 15px;
  margin-left: 12px;
  width: 96px;
  height: 34px;
  position: absolute;
}

.por{
  position: relative;
}

.bodyimg{
  width: 100%;
  height: 344px;
}

.time{
  font-weight: bold;
  position: absolute;
  padding: 5px 15px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.89);
  white-space: nowrap;
  border-radius: 20px;
  font-size: 14px;
  color: #F15989;
  text-align: center;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.titleimg{
  display: flex;
  justify-content: center;
  margin-top: -16px;
}

.font{
  font-size: 14px;
  color: #fff;
  width: 333px;
  height: 40px;
  margin: 0 auto;
  margin-top: 12px;
}

.but{
  margin: 0 auto;
  margin-top: 12px;
  background-color: #DF4877;
  border-radius: 40px;
  display: flex;
  width: fit-content;
}

.but-item{
  padding: 6px 21px;
  box-sizing: border-box;
  font-size: 14px;
  color: #fff;
  border-radius: 40px;
}

.active{
  color: #F15989;
  background-color: #fff;
  font-weight: 600;
}

.table{
  padding: 0 20px;
  box-sizing: border-box;
}

.tableBg{
  background-color: #FFC4D7;
  width: 100%;
  border-radius: 16px;
  border: 8px solid rgba(255, 160, 190, 0.8);
  margin-top: 14px;
  padding: 18px;
  box-sizing: border-box;
}

.tabTitle{
  color: #301C20;
  font-weight: 600;
  text-align: center;
}

.tabData{
  border-radius: 8px;
  border: 1px solid #301C20;
  box-sizing: border-box;
  margin-top: 16px;
}

.dataT{
  display: flex;
  background-color: #FFAAC5;
  padding: 10px 16px;
  box-sizing: border-box;
  
  .top{
    width: 17%;
    text-align: center;
  }

  .name{
    width: 60%;
    text-align: center;
  }

  .top2{
    width: 23%;
    text-align: center;
  }

  .item{
    width: 50%;
    color: #301C20;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
}

.tabItem{
  display: flex;
  background-color: #FFE4ED;
  padding: 10px 16px;
  box-sizing: border-box;
  
  .top{
    width: 17%;
    text-align: center;
  }

  .name{
    width: 60%;
    text-align: center;
  }

  .top2{
    width: 23%;
    text-align: center;
  }

  .item{
    width: 50%;
    color: #301C20;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
}

.itemBg{
  background-color: #FFD6E3;
}

.bor{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.font1{
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
</style>