<template>
  <div>
    <div class="input_boxb">
      <img src="./image/ok.png" alt="" />
      <div>交易成功</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "wxView",
  data() {
    return {
      amount: "",
    };
  },
  methods: {
    backs() {
      this.$router.push("/");
    },
  },
};
</script>
<style>
.input_boxb {
  width: 100%;
  text-align: center;
  margin-top: 100px;
  img {
    width: 50px;
    height: 50px;
  }
}
</style>
