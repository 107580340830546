import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import jtView from "../views/jtView.vue";
import wxView from "../views/succeed.vue";
import demoVview from "../views/demo.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: jtView,
  },
  {
    path: "/succeed",
    name: "succeed",
    component: wxView,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
