import request from "../utils/request.js";

export const getConfig = () =>
  request({
    url: "/getH5Url",
    method: "get",
  });
export const getOpen = (data) =>
  request({
    url: "/pay",
    method: "post",
    data: data,
  });
